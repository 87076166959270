/* Aumentando a margem entre os cards */
.col-md-4.col-sm-6.mb-4 {
    margin-bottom: 40px !important;  /* Aumentar para mais espaço vertical */
}

/* Aumentando o espaço entre o título e os cards */
.container h4 {
    margin-bottom: 20px; /* Aumentar conforme necessário para mais espaço abaixo do título */
}

.project-card {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease-in-out;
    padding: 20px; /* Adicionar padding interno para mais espaço dentro dos cards */
}

.project-card:hover {
    transform: translateY(-5px);
}

.card-body {
    padding: 15px;  /* Ajustar o padding interno dos cards */
}

.card-text {
    margin-bottom: 20px; /* Ajustar margem abaixo do texto dentro do card */
}

.btn-primary {
    background-color: #007bff !important;  /* Pode ajustar a cor para combinar com o seu tema */
}

.container {
    max-width: 1140px; /* Ajuste conforme a largura máxima desejada */
    padding: 0 15px; /* Adicionar padding lateral para alinhar o conteúdo dentro do container */
}
